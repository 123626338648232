<template>
  <b-container fluid="md" class="px-0">
    <h1>Logout</h1>

    <h3 class="text-danger">Are you sure?</h3>

    <div class="d-flex justify-content-center mt-4">
      <b-button variant="success" class="m-1 px-5" :to="{ name: 'home' }"
        >No</b-button
      >
      <b-button variant="outline-danger" class="m-1" @click="logout"
        >Yes</b-button
      >
    </div>
  </b-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions(["logout"]),
  },
};
</script>